<div class="gap-5 flex flex-col justify-between ml-4 md:mr-10 md:ml-10">
  <div
    class="font-poppins text-[18px] md:text-2xl text-[#C44055] text-center md:my-6 w-fit"
  >
    ExcelFile
  </div>

  <div class="flex p-2">
    <mat-form-field>
      <mat-label>faculty</mat-label>
      <mat-select [formControl]="facultyCtrl">
        @for (f of getFaculties(); track f) {
        <mat-option [value]="f">
          {{ f }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex p-2">
    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select [formControl]="categoryCtrl">
        @for (c of categories; track c.name) {
        <mat-option [value]="c.name">
          {{ c.name }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div [formGroup]="questionForm" class="flex flex-col place-items-start p-2">
    <mat-form-field class="example-full-width">
      <mat-label>Select Chapter</mat-label>

      <mat-select [formControl]="chapterCtrl">
        @for (qg of questionGroup$ | async; track qg) {
        <mat-option [value]="qg.id">{{ qg.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Select Sub Chapter</mat-label>

      <mat-select [formControl]="subChapterCtrl">
        @for (sc of subChapters$ | async; track sc.id) {
        <mat-option [value]="sc.id">{{ sc.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Select Unit</mat-label>

      <mat-select formControlName="unit">
        @for (u of units$ | async; track u.id) {
        <mat-option [value]="u.id">{{ u.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <input
    type="file"
    (change)="handleFileInput($event)"
    placeholder="Insert file"
  />

  <div class="flex gap-3">
    <button
      (click)="submit()"
      mat-raised-button
      disableOnLoading="questions/bulk-import-excel"
      color="primary"
      class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
    >
      Save
    </button>
  </div>
</div>
