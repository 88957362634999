<div class="gap-5 flex justify-between ml-4 md:mr-10 md:ml-10">
  <div
    class="font-poppins w-full text-[18px] md:text-2xl text-[#C44055] text-center md:my-6"
  >
    Copy Unit
  </div>
</div>
<div
  class="flex flex-col gap-2 w-fit mx-auto shadow-md border-[2px] border-gray-100 rounded-sm"
>
  <div
    [formGroup]="questionForm"
    class="flex flex-wrap flex-row justify-center place-items-center px-4"
  >
    <div class="flex flex-col p-2">
      <mat-label>From</mat-label>

      <div class="flex p-2">
        <mat-form-field>
          <mat-label>faculty</mat-label>
          <mat-select [formControl]="facultyCtrl1">
            @for (f of getFaculties(); track f) {
            <mat-option [value]="f">
              {{ f }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex p-2">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [formControl]="categoryCtrl1">
            @for (c of categories1; track c.name) {
            <mat-option [value]="c.name">
              {{ c.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex p-2">
        <mat-form-field class="example-full-width">
          <mat-label>Select Chapter</mat-label>

          <mat-select [formControl]="chapterCtrl1">
            @for (qg of questionGroup1$ | async; track qg) {
            <mat-option [value]="qg.id">{{ qg.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-col gap-1">
        <mat-form-field class="example-full-width">
          <mat-label>Select Sub Chapter</mat-label>

          <mat-select [formControl]="subChapterCtrl1">
            @for (sc of subChapters1$ | async; track sc.id) {
            <mat-option [value]="sc.id">{{ sc.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Select Unit</mat-label>

          <mat-select formControlName="fromUnitId" required>
            @for (u of units1$ | async; track u.id) {
            <mat-option [value]="u.id">{{ u.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="text-2xl font-semibold">-----></div>
    <div class="flex flex-col p-2">
      <mat-label>To</mat-label>
      <div class="flex p-2">
        <mat-form-field>
          <mat-label>faculty</mat-label>
          <mat-select [formControl]="facultyCtrl2">
            @for (f of getFaculties(); track f) {
            <mat-option [value]="f">
              {{ f }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex p-2">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [formControl]="categoryCtrl2">
            @for (c of categories2; track c.name) {
            <mat-option [value]="c.name">
              {{ c.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex p-2">
        <mat-form-field class="example-full-width">
          <mat-label>Select Chapter</mat-label>

          <mat-select [formControl]="chapterCtrl2">
            @for (qg of questionGroup2$ | async; track qg) {
            <mat-option [value]="qg.id">{{ qg.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-col gap-1">
        <mat-form-field class="example-full-width">
          <mat-label>Select Sub Chapter</mat-label>

          <mat-select [formControl]="subChapterCtrl2">
            @for (sc of subChapters2$ | async; track sc.id) {
            <mat-option [value]="sc.id">{{ sc.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Select Unit</mat-label>

          <mat-select formControlName="toUnitId" required>
            @for (u of units2$ | async; track u.id) {
            <mat-option [value]="u.id">{{ u.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="flex gap-3">
    <button
      (click)="submit()"
      [disabled]="
        questionForm.get('fromUnitId')?.invalid ||
        questionForm.get('toUnitId')?.invalid
      "
      disableOnLoading="questions/copy-unit/from"
      mat-raised-button
      color="primary"
      class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
    >
      Save
    </button>
    <!-- <a>
        <button
          mat-raised-button
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
          >
          Cancel
        </button>
      </a> -->
  </div>
</div>
