import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, computed } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuestionGroup } from '../questions/questions.model';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionService } from '../questions/questions/questions.service';
import { FilterDTO } from '../app.model';
import { QuestionGroupService } from '../questions/Questions-Group/questions.service';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { Unit, UnitService } from '../unit.service';
import { SubChapter, SubChapterService } from '../subChapter.service';
import { DisableOnLoadingDirective } from '../disable-on-loading.directive';
import { AppService, Branch } from '../app.service';

@Component({
  selector: 'app-excelfileupload',
  templateUrl: './excelfileupload.component.html',
  styleUrls: ['./excelfileupload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    UploadFileComponent,
    FileUploadComponent,
    MatAutocompleteModule,
    MatInputModule,
    DisableOnLoadingDirective,
  ],
})
export class ExcelfileuploadComponent implements OnInit {
  constructor(
    private questionGroup: QuestionGroupService,
    private questionService: QuestionService,
    private subChapterService: SubChapterService,
    private unitService: UnitService,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private toast: ToastrService,
    private appService: AppService
  ) {
    this.questionForm = this.fb.group({
      unit: new FormControl(),
    });
  }

  facultyCtrl = new FormControl();
  chapterCtrl = new FormControl(undefined);
  subChapterCtrl = new FormControl(undefined);
  categoryCtrl = new FormControl();
  faculties = computed(() => this.appService.appConstant$()?.faculties);
  categories: Branch[] = [];
  questionForm: FormGroup;
  excelfileUploadCtrl = new FormControl<string>('');
  questionGroup$!: Observable<QuestionGroup[]> | undefined;
  subChapters$: Observable<SubChapter[]> | undefined;
  units$: Observable<Unit[]> | undefined;
  displayFn(questionGroup: any): string {
    return questionGroup && questionGroup.title ? questionGroup.title : '';
  }

  ngOnInit(): void {
    this.facultyCtrl.valueChanges
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((v) => {
        this.categories = this.getcategories(v!.toString());
        this.categoryCtrl.setValue(this.categories[0].name);
      });
    this.questionGroup$ = this.categoryCtrl.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      startWith(this.categoryCtrl.value),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          params: {
            category: this.categoryCtrl.value,
            faculty: this.facultyCtrl.value,
          },
        };
        return this.questionGroup
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );

    this.subChapters$ = this.chapterCtrl.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          search: `questionGroup.id:${v}`,
        };
        return this.subChapterService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
    this.units$ = this.subChapterCtrl.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          search: `subChapter.id:${v}`,
        };
        return this.unitService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
  }
  fileToUpload: File | null = null;
  handleFileInput(event: any) {
    var files = event.target.files;
    if (files && files.length > 0) {
      this.fileToUpload = files.item(0);
    }
  }
  submit() {
    if (this.questionForm.valid && this.fileToUpload) {
      this.questionService
        .uploadExcel(this.fileToUpload, this.questionForm.value.unit)
        .subscribe(() => {
          this.toast.success('Data saved successfully');
          console.log('Data saved successfully');
        });
    } else {
      this.toast.error('Form is invalid or file is not selected');
      console.error(
        'Form is invalid or file is not selected',
        this.fileToUpload,
        this.questionForm.valid
      );
    }
  }
  getFaculties(): string[] {
    const f = this.faculties();
    if (f) return Object.keys(f);
    return [];
  }
  getcategories(f: string) {
    const facultyValue = f;
    const ff = this.faculties();
    if (ff && typeof facultyValue === 'string' && ff[facultyValue]) {
      return ff[facultyValue]!;
    }
    return [];
  }
}
