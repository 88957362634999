import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { SubChapter } from './subChapter.service';

export interface Unit {
  id?: number;
  title: string;
  details: string;
  subChapterId?: number;
  subChapter?: SubChapter;
}
@Injectable({
  providedIn: 'root',
})
export class UnitService extends AbstractService<Unit> {
  override serviceName(): string {
    return 'units';
  }
}
