import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, catchError, finalize, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { RestResponse } from './app.model';
import { KeycloakService } from 'keycloak-angular';
import { trimObjectValues } from './Utils';
import { AppService } from './app.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastRService: ToastrService,
    private keycloak: KeycloakService,
    private appService: AppService
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const requestUrl = request.url; // Full URL
    const pathWithoutDomain = requestUrl.split('/').slice(3).join('/');
    this.appService.setLoading({ route: pathWithoutDomain, loading: true });
    //skip some endpoint token
    const skipIntercept = request.headers.has('skip');
    let customized = request.headers;
    if (!skipIntercept && this.authService.token) {
      customized = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this.authService.token}`,
      });
    } else {
      customized.delete('skip');
    }

    request = request.clone({
      headers: customized,
      body: trimObjectValues(request.body),
    });
    //console.log("skip inter", skipIntercept, request);
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const response = event.body as RestResponse;
          if (response.statusCode == 200) {
            event = event.clone({
              body: response.body,
            });
          } else {
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            {
              this.keycloak.logout().then(() => this.keycloak.login());
            }
            break;
          case 400: {
            let errorMessage = error.error.message;
            const errors = error.error['Errors'] as string[];
            if (errors?.length > 0)
              errorMessage = errors.reduce((acc, curr) => acc + ' & ' + curr);
            this.toastRService.error(errorMessage);
            break;
          }
          case 403: {
            this.toastRService.error(error.error.message);
            break;
          }
          case 409: {
            this.toastRService.error(error.error.message);
            break;
          }
          case 500: {
            this.toastRService.error(error.error.message);
            break;
          }
        }
        return EMPTY;
      }),
      finalize(() =>
        this.appService.setLoading({ route: pathWithoutDomain, loading: false })
      )
    );
  }
}
