import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RestResponse, User } from './app.model';
import { Observable, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwtHelperService = new JwtHelperService();
  private readonly ISSUER = 'https://yashilabs.com.np';
  private readonly IMAGE = 'rock.jpg';

  public isAdmin() {
    return (
      this.jwtHelperService
        .decodeToken<MyJwtType>(this.token)
        ?.scope.toLowerCase() == 'admin'
    );
  }
  public isAuthenticated() {
    const isExpired = this.jwtHelperService.isTokenExpired(this.token);
    if (!isExpired) {
      return (
        this.jwtHelperService.decodeToken<MyJwtType>(this.token)?.iss ==
        this.ISSUER
      );
    }
    return false;
  }

  public getUsername() {
    return this.jwtHelperService.decodeToken<MyJwtType>(this.token)?.sub;
  }

  public getUserId() {
    return this.jwtHelperService.decodeToken<MyJwtType>(this.token)?.user_id;
  }

  public getDeliveryAddress() {
    return this.jwtHelperService.decodeToken<MyJwtType>(this.token)?.address;
  }
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public get token(): any {
    return localStorage.getItem('access_token');
  }

  public get user(): User {
    const user = localStorage.getItem('user');
    return JSON.parse(user!) as User;
  }
  public set token(token: string) {
    localStorage.setItem('access_token', token);
  }

  public set user(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  login(username: string, password: string): Observable<RestResponse> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + btoa(`${username}:${password}`));

    return this.http
      .post<RestResponse>(this.apiURL + 'token', undefined, {
        headers: headers,
      })
      .pipe(
        tap((res) => {
          this.token = res.access_token!;
          this.user = res.user!;
        }),
        tap((res) => {
          console.log('token', res);
        })
      );
  }

  changePassword(oldPass: string, newPass: string): Observable<RestResponse> {
    return this.http
      .post<RestResponse>(this.apiURL + 'change-password', {
        prevPassword: oldPass,
        password: newPass,
      })
      .pipe(
        tap((res) => res.access_token),
        tap((res) => {
          console.log('token', res);
        })
      );
  }

  forgotPassword(username: string): Observable<RestResponse> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');

    return this.http.post<RestResponse>(
      `${this.apiURL}forgot-password/${username}`,
      null,
      {
        headers: headers,
      }
    );
  }

  resetForgotPassword(reset: {
    token: string;
    password: string;
  }): Observable<RestResponse> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');

    return this.http.post<RestResponse>(this.apiURL + 'reset-password', reset, {
      headers: headers,
    });
  }

  public signup(
    username: string,
    email: string,
    mobileNo: number,
    password: string,
    address?: string
  ): Observable<RestResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.post<RestResponse>(
      `${this.apiURL}register`,
      {
        username: username,
        email: email,
        phoneNumber: mobileNo,
        password: password,
        address: address,
      },
      httpOptions
    );
  }
}

export interface MyJwtType {
  exp: number;
  iat: number;
  iss: string;
  scope: string;
  sub: string;
  user_id: string;
  address: string;
  phone: number;
  img: string;
  email: string;
}
