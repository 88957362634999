import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { QuestionGroup } from './questions/questions.model';

export interface SubChapter {
  id?: number;
  title: string;
  details: string;
  questionGroupId?: number;
  questionGroup?: QuestionGroup;
}

@Injectable({
  providedIn: 'root',
})
export class SubChapterService extends AbstractService<SubChapter> {
  serviceName(): string {
    return 'sub-chapters';
  }
}
