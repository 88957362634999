import { Component, DestroyRef, computed } from '@angular/core';
import { QuestionGroup } from '../questions/questions.model';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { FilterDTO } from 'src/app/app.model';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionGroupService } from '../questions/Questions-Group/questions.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { QuestionService } from '../questions/questions/questions.service';
import { Unit, UnitService } from '../unit.service';
import { SubChapter, SubChapterService } from '../subChapter.service';
import { DisableOnLoadingDirective } from '../disable-on-loading.directive';
import { AppService, Branch } from '../app.service';

@Component({
  selector: 'app-convertchapter',
  templateUrl: './convertchapter.component.html',
  styleUrls: ['./convertchapter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DisableOnLoadingDirective,
  ],
})
export class ConvertchapterComponent {
  questionGroup1$!: Observable<QuestionGroup[]> | undefined;
  questionGroup2$!: Observable<QuestionGroup[]> | undefined;

  subChapters1$: Observable<SubChapter[]> | undefined;
  units1$: Observable<Unit[]> | undefined;

  subChapters2$: Observable<SubChapter[]> | undefined;
  units2$: Observable<Unit[]> | undefined;
  totalElements: number | undefined;
  questionGListChanges$ = new Subject<void>();

  faculties = computed(() => this.appService.appConstant$()?.faculties);

  facultyCtrl1 = new FormControl('ENGINEERING');
  chapterCtrl1 = new FormControl(undefined);
  subChapterCtrl1 = new FormControl(undefined);

  facultyCtrl2 = new FormControl('ENGINEERING');
  chapterCtrl2 = new FormControl(undefined);
  subChapterCtrl2 = new FormControl(undefined);

  categories1: Branch[] = [];
  categories2: Branch[] = [];
  dataSource!: MatTableDataSource<QuestionGroup>;

  constructor(
    private destroyRef: DestroyRef,
    private questionGroup: QuestionGroupService,
    private fb: FormBuilder,
    private toast: ToastrService,
    private questionService: QuestionService,
    private subChapterService: SubChapterService,
    private unitService: UnitService,
    private appService: AppService
  ) {
    this.questionForm = this.fb.group({
      fromUnitId: new FormControl(),
      toUnitId: new FormControl(),
    });
  }
  categoryCtrl1 = new FormControl('CIVIL');
  categoryCtrl2 = new FormControl('CIVIL');
  questionForm: FormGroup;
  displayFn(questionGroup: any): string {
    return questionGroup && questionGroup.title ? questionGroup.title : '';
  }
  ngOnInit(): void {
    this.facultyCtrl1.valueChanges
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((v) => {
        this.categories1 = this.getcategories(v!.toString());
        this.categoryCtrl1.setValue(this.categories1[0].name);
      });

    this.facultyCtrl2.valueChanges
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((v) => {
        this.categories2 = this.getcategories(v!.toString());
        this.categoryCtrl2.setValue(this.categories2[0].name);
      });
    this.questionGroup1$ = this.categoryCtrl1.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      startWith(this.categoryCtrl1.value),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          params: {
            category: this.categoryCtrl1.value,
            faculty: this.facultyCtrl1.value,
          },
        };
        return this.questionGroup
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );

    this.questionGroup2$ = this.categoryCtrl2.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      startWith(this.categoryCtrl2.value),
      switchMap((v) => {
        this.subChapterCtrl2.setValue(null);
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          params: {
            category: this.categoryCtrl2.value,
            faculty: this.facultyCtrl2.value,
          },
        };
        return this.questionGroup
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
    this.subChapters1$ = this.chapterCtrl1.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        this.questionForm.get('fromUnitId')?.setValue(null);
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          search: `questionGroup.id:${v}`,
        };
        return this.subChapterService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
    this.units1$ = this.subChapterCtrl1.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          search: `subChapter.id:${v}`,
        };
        return this.unitService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );

    this.subChapters2$ = this.chapterCtrl2.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          search: `questionGroup.id:${v}`,
        };
        this.questionForm.get('toUnitId')?.setValue(null);
        return this.subChapterService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
    this.units2$ = this.subChapterCtrl2.valueChanges.pipe(
      filter((v) => !!v),
      takeUntilDestroyed(this.destroyRef),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap((v) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 100,
          search: `subChapter.id:${v}`,
        };
        return this.unitService
          .filterData(filter)
          .pipe(map((page) => page.elements));
      })
    );
  }
  submit() {
    if (this.questionForm.valid) {
      const { fromUnitId, toUnitId } = this.questionForm.value;

      this.questionService.copyUnit(fromUnitId, toUnitId).subscribe(() => {
        this.toast.success('Copied Succesfully');
        console.log('Data saved successfully');
      });
    } else {
      this.toast.error('Invalid please try again');
      console.error('Form is invalid or file is not selected');
    }
  }
  getFaculties(): string[] {
    return Object.keys(this.faculties() ?? {});
  }
  getcategories(facultyValue: string) {
    const f = this.faculties();
    if (f && typeof facultyValue === 'string' && f[facultyValue]) {
      return f[facultyValue]!;
    }
    return [];
  }
}
