import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ViewChild,
  computed,
  effect,
  inject,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { JsonPipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { QuestionGroup } from '../questions.model';
import { QuestionGroupService } from './questions.service';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FilterDTO } from 'src/app/app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionGroupAddComponent } from './question-group-add/question-group-add.component';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { DisableOnLoadingDirective } from 'src/app/disable-on-loading.directive';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-questions',
  templateUrl: './questionsGroup.component.html',
  styleUrls: ['./questionsGroup.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
    DisableOnLoadingDirective,
  ],
})
export class QuestionsGroupComponent implements AfterViewInit {
  destoryRef = inject(DestroyRef);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'id',
    'title',
    'faculty',
    'category',
    'details',
    'subChapter',
    'action',
  ];
  totalElements: number | undefined;
  dataSource!: MatTableDataSource<QuestionGroup>;
  questionGroupNameCtrl = new FormControl<string>('');
  questionGrp$: Observable<QuestionGroup[]> | undefined;
  questionGListChanges$ = new Subject<void>();
  appService = inject(AppService);

  categoryCtrl = new FormControl(
    this.route.snapshot.queryParamMap.get('category') ?? 'CIVIL'
  );

  faculties = computed(() => this.appService.appConstant$()?.faculties);
  facultyCtrl = new FormControl(
    (this.route.snapshot.queryParamMap.get('faculty') as any) ?? 'ENGINEERING'
  );

  getFaculties(): string[] {
    const f = this.faculties();
    if (f) return Object.keys(f);
    return [];
  }

  get categories() {
    const facultyValue = this.facultyCtrl.value;
    const f = this.faculties();
    if (f && typeof facultyValue === 'string' && f[facultyValue]) {
      return f[facultyValue]!;
    }
    return [];
  }

  constructor(
    private dialog: MatDialog,
    private questionService: QuestionGroupService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngAfterViewInit(): void {
    // this.questionGrp$ =

    this.facultyCtrl.valueChanges.subscribe(() => {
      this.categoryCtrl.setValue(null);
    });
    merge(
      this.questionGListChanges$,
      this.paginator.page,
      this.categoryCtrl.valueChanges.pipe(
        filter((category) => !!category && !!this.facultyCtrl.value),
        tap((category) =>
          this.changeCategoryQueryParam(
            this.facultyCtrl.value!.toString(),
            category!
          )
        )
      )
    )
      .pipe(
        startWith({}),
        switchMap(() => {
          const filterDTO: FilterDTO = {
            pageNumber: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize,
            params: {
              faculty: this.facultyCtrl.value,
              category: this.categoryCtrl.value,
            },
          };

          return this.questionService.filterData(filterDTO).pipe(
            tap((page) => (this.totalElements = page.totalElements)),
            map((page) => page.elements)
          );
        })
      )
      .subscribe((questionGList) => {
        this.dataSource = new MatTableDataSource(questionGList);
        this.questionGListChanges$, this.dataSource._updateChangeSubscription();
        this.cdr.detectChanges();
      });
  }

  deleteGroup(id: number) {
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.questionService
        .delete(id)
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastrService.success('Deleted successfully', '', {
            timeOut: 3000,
          });

          this.questionGListChanges$.next();
        });
    }
  }

  openQuestionGroup() {
    this.dialog
      .open(QuestionGroupAddComponent, {
        width: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) this.questionGListChanges$.next();
      });
  }
  changeCategoryQueryParam(faculty: string, category: string) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        faculty: faculty,
        category: category,
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false,
      // do not trigger navigation
    });
  }
}
