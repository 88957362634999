import { Unit } from '../unit.service';
import { LANGUAGE } from './questions/questions-add/questions-add.component';

export interface QuestionGroup {
  id?: number;
  title: string;
  details: string;
  faculty: string;
  category?: string;
  createdTime?: Date;
}
export interface QuestionSet {
  title: string;
  runningTime?: Date;
  requiredCoins?: number;
  unitId?: number;
  id?: number;
}
export interface Question {
  id?: number;
  title: string;
  category: string;
  file?: S3Object;
  level: string;
  unit?: Unit;
  options: Options[];
  correctOptionReason: string;
  questionSetId?: number;
  code?: {
    language: LANGUAGE;
    snippet: string;
  } | null;
}

export interface Options {
  id?: number;
  name: string;
  correct: boolean;
}

export interface S3Object {
  id?: number;
  key: string;
  alt?: string;
}

export enum Level {
  INTERMEDIATE = 'INTERMEDIATE',
  EASY = 'EASY',
  HARD = 'HARD',
  LEGENDARY = 'LEGENDARY',
}
export enum CATEGORY {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  CODE = 'CODE',
}
export enum PRIORITY {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
