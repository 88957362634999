<div class="gap-5 flex flex-row justify-between mr-10 ml-10">
  <div
    class="font-poppins text-xl md:text-2xl text-[#C44055] text-center w-fit"
  >
    Question Sets
  </div>
  <a class="" (click)="openQuestionSet()">
    <button
      class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-md md:text-xl px-2 md:px-5 py-2 rounded-lg"
    >
      Add Questions Set
    </button>
  </a>
</div>

<div class="p-4 w-full">
  <span class="text-xl font-semibold">{{ chapter }} > </span>
  <span class="text-xl font-semibold">{{ subChapter }} > </span>
  <span class="text-xl font-semibold">{{ unit }} </span>
  <div
    class="shadow-black bg-gray-100 border border-gray-400 rounded-lg mx-auto min-w-[300px] max-w-[1024px]"
  >
    <div class="flex flex-wrap flex-row justify-between px-4">
      <div class="flex flex-wrap items-center sm:gap-14 gap-3 my-auto mx-3">
        <form class="example-form mt-6">
          <mat-form-field class="example-full-width">
            <mat-label>Search......</mat-label>
            <input [formControl]="questionSetCtrl" type="text" matInput />
          </mat-form-field>
        </form>
      </div>
      <!-- <div class="flex p-2">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [formControl]="categoryCtrl">
            <mat-option *ngFor="let categorys of Categorys" [value]="categorys">
              {{ categorys }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>
    <div class="overflow-x-scroll">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8 bg-gray-100"
      >
        <ng-container matColumnDef="id">
          <div class="flex gap-2">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex gap-2 font-bold">ID <button></button></div>
            </th>
          </div>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Title<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="questions">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Question<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              class="p-2 bg-gray-400 rounded-xl"
              [routerLink]="['/home/question-set-questions/' + element.id]"
              [queryParams]="{
                chapter: chapter,
                subChapter: subChapter,
                unit: unit,
                set: element.title
              }"
            >
              View Questions
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="runningTime">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">
              Running Time<button></button>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.runningTime }}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Category<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.category }}
          </td>
        </ng-container> -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="font-bold">Action</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button>
                <a
                  [routerLink]="['/home/questionSet-edit/' + element.id]"
                  href=""
                >
                  <mat-icon>edit</mat-icon>
                </a>
              </button>
              <button (click)="deleteGroup(element.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="100"
  aria-label="Select page"
>
</mat-paginator>
