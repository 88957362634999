import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { Observable, debounceTime, filter, map, switchMap } from 'rxjs';
import { CoinTransactionService } from '../coin-transaction.service';
import { User, UserService } from '../user.service';
import { FilterDTO } from '../app.model';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-coin',
  standalone: true,
  templateUrl: './create-coin.component.html',
  styleUrl: './create-coin.component.scss',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatCheckbox,
    MatSelect,
    MatLabel,
    MatOption,
    MatInput,
    MatButton,
    AsyncPipe,
    MatAutocompleteModule,
    JsonPipe,
  ],
})
export class CreateCoinComponent implements OnInit {
  readonly coinFeatures = ['MOCKUP', 'REFUND', 'REWARD', 'REGISTER_REWARD'];
  readonly coinForm = new FormGroup({
    quantity: new FormControl(1),
    coinFeature: new FormControl('REWARD'),
    transactionType: new FormControl('BUY'),
    user: new FormControl<User | null>(null),
    all: new FormControl(false),
    message: new FormControl<string | null>(null),
  });
  readonly userCtrl = new FormControl<string | null>(null);
  readonly CoinTransactionService = inject(CoinTransactionService);
  readonly userService = inject(UserService);
  readonly destryoyRef = inject(DestroyRef);
  readonly toast = inject(ToastrService);
  users$!: Observable<User[]> | undefined;
  ngOnInit(): void {
    this.users$ = this.coinForm.get('user')?.valueChanges.pipe(
      debounceTime(500),
      filter((s) => !!s),
      switchMap((s) => {
        const filter: FilterDTO = {
          pageNumber: 1,
          pageSize: 10,
          search: `email:${s}*`,
        };
        return this.userService.filterData(filter).pipe(map((p) => p.elements));
      })
    );
  }

  displayFn(user: any): string {
    console.log('user ', user);
    return user && user.email ? user.email : '';
  }

  submit() {
    const f = this.coinForm.value;
    const cr = {
      ...f,
      userId: f.all ? 'ALL' : f.user?.id,
    };
    this.CoinTransactionService.buyCoinByAdmin(cr)
      .pipe(takeUntilDestroyed(this.destryoyRef))
      .subscribe(() => {
        this.toast.success('Coin was added successfully');
        this.coinForm.reset();
      });
  }
}
