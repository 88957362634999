<div class="gap-5 flex justify-between ml-4 md:mr-10 md:ml-10">
  <div
    class="font-poppins text-[18px] md:text-2xl text-[#C44055] text-center md:my-6 w-fit"
  >
    Chapter
  </div>
  <a (click)="openQuestionGroup()" class="mr-3">
    <button
      disableOnLoading="question-groups/filter"
      class="bg-[#04D9FF] text-white hover:bg-gray-300 focus:outline-none focus:bg-indigo-600 font-poppins text-[18px] md:text-xl px-5 py-2 rounded-lg"
    >
      Add Chapter
    </button>
  </a>
</div>
<div class="p-4 w-full">
  <div
    class="shadow-black bg-gray-100 border border-gray-400 rounded-lg mx-auto min-w-[300px] max-w-[1024px]"
  >
    <div class="flex flex-wrap flex-row justify-between px-4">
      <div class="flex flex-wrap items-center sm:gap-14 gap-3 my-auto mx-3">
        <form class="example-form mt-6">
          <mat-form-field class="example-full-width">
            <mat-label>Faculty</mat-label>
            <mat-select [formControl]="facultyCtrl">
              @for (faculty of getFaculties(); track faculty) {
              <mat-option [value]="faculty">
                {{ faculty }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="flex p-2">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [formControl]="categoryCtrl">
            @for (branch of categories; track branch.name) {
            <mat-option [value]="branch.name">
              {{ branch.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="overflow-x-scroll">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8 bg-gray-100"
      >
        <ng-container matColumnDef="id">
          <div class="flex gap-2">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex gap-2 font-bold">ID <button></button></div>
            </th>
          </div>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Title<button></button></div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.title }}
          </td>
        </ng-container>
        <ng-container matColumnDef="faculty">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Faculty</div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.faculty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Category</div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.category }}
          </td>
        </ng-container>
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Details</div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.details }}
          </td>
        </ng-container>
        <ng-container matColumnDef="subChapter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-2 font-bold">Sub Chapter</div>
          </th>
          <td mat-cell class="" *matCellDef="let element">
            <button
              class="p-2 m-2 bg-gray-400 rounded-xl"
              [routerLink]="['/home/sub-Chapter/' + element.id]"
              [queryParams]="{ chapter: element.title }"
            >
              View Sub Chapter
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button>
                <a
                  [routerLink]="['/home/questionGroup-edit/' + element.id]"
                  href=""
                >
                  <mat-icon>edit</mat-icon>
                </a>
              </button>
              <button (click)="deleteGroup(element.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator
  #paginator
  class="demo-paginator"
  [length]="totalElements"
  [pageSize]="10"
  aria-label="Select page"
>
</mat-paginator>
