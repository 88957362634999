import { Component, DestroyRef, TemplateRef } from '@angular/core';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { Options, Question } from '../questions/questions.model';
import { QuestionSetQuestionService } from '../question-set-question.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterDTO } from '../app.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatAutocompleteModule,
  MatOption,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionService } from '../questions/questions/questions.service';
import { OptionsService } from '../options/options.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-question-set-questions',
  standalone: true,
  imports: [
    AsyncPipe,
    MatIcon,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOption,
    MatInput,
    ReactiveFormsModule,
    RouterLink,
    NgClass,
    FormsModule,
  ],
  templateUrl: './question-set-questions.component.html',
  styleUrl: './question-set-questions.component.scss',
})
export class QuestionSetQuestionsComponent {
  dataSource!: Observable<Question[]>;
  searchedQuestions!: Observable<Question[]>;
  id: number | undefined;
  totalElements: number | undefined;
  questionGListChanges$ = new Subject<void>();
  questionNameCtrl = new FormControl('');

  readonly unit = this.route.snapshot.queryParamMap.get('unit');
  readonly subChapter = this.route.snapshot.queryParamMap.get('subChapter');
  readonly chapter = this.route.snapshot.queryParamMap.get('chapter');
  readonly set = this.route.snapshot.queryParamMap.get('set');
  constructor(
    private questionSetQuestionService: QuestionSetQuestionService,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    private destoryRef: DestroyRef,
    private toastService: ToastrService,
    private optionService: OptionsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.searchedQuestions = this.questionNameCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      filter((q) => !!q && typeof q !== 'object'),
      switchMap((q) => this.questionService.searchQuestions(q!))
    );
    this.dataSource = this.route.params.pipe(
      map((params) => params['id']),
      filter((id) => id != null && id != undefined),
      tap((id) => (this.id = id)),
      switchMap((id) =>
        merge(this.questionGListChanges$).pipe(
          startWith({}),
          switchMap(() => {
            const filterDTO: FilterDTO = {
              pageNumber: 1,
              pageSize: 100,
              params: {
                questionSetId: id,
              },
            };
            return this.questionSetQuestionService.filter(filterDTO).pipe(
              tap((page) => (this.totalElements = page.totalElements)),
              map((page) => page.elements)
            );
          })
        )
      ),
      shareReplay()
    );
  }

  delete(questionId: number | undefined) {
    if (!this.id || !questionId) return;
    const delBtn = confirm(' Do you want to delete ?');
    if (delBtn == true) {
      this.questionSetQuestionService
        .del({ question: questionId, questionSet: this.id })
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastService.success('Deleted Sucessfully', '', {
            timeOut: 3000,
          });
          this.questionGListChanges$.next();
        });
    }
  }
  addQuestionToSet(event: any) {
    const question = event.option.value;
    this.questionNameCtrl.setValue(null);
    if (question.id && this.id) {
      this.questionSetQuestionService
        .create({
          question: question.id,
          questionSet: Number(this.id),
        })
        .pipe(takeUntilDestroyed(this.destoryRef))
        .subscribe(() => {
          this.toastService.success('Added the question');
          this.questionGListChanges$.next();
        });
    }
  }

  displayFn(q: Question) {
    return q.title;
  }

  optionDialogRef!: MatDialogRef<any>;
  openOptionEditDialog(ref: TemplateRef<any>, option: Options) {
    this.optionDialogRef = this.dialog.open(ref, {
      data: option,
      disableClose: true,
    });
  }

  editOption(option: Options) {
    if (!option.id) return;
    this.optionService.update(option.id, option).subscribe(() => {
      this.toastService.success('Option was edited');
      this.questionGListChanges$.next();
      this.optionDialogRef.close();
    });
  }

  cancelOptionDialog() {
    this.questionGListChanges$.next();
  }
}
