import { Directive, ElementRef, input } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { AppService } from './app.service';

@Directive({
  selector: '[disableOnLoading]',
  standalone: true,
})
export class DisableOnLoadingDirective {
  readonly disableOnLoading = input.required<
    `${string}` | `${string}/${string}` | `${string}/${string}/${string}`
  >();
  private loadingSubscription!: Subscription;
  private isDisabled: boolean = false;

  constructor(private el: ElementRef, private appService: AppService) {}
  ngOnInit() {
    this.loadingSubscription = this.appService.isLoading$
      .pipe(filter((r) => r !== undefined))
      .subscribe((rl) => {
        if (!rl) return;
        const { route, loading } = rl;
        if (route.startsWith(this.disableOnLoading())) {
          this.isDisabled = loading;
          this.updateElementState();
        }
      });
  }

  ngOnDestroy() {
    // Clean up the subscription
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  private updateElementState() {
    if (this.isDisabled) {
      this.el.nativeElement.setAttribute('disabled', 'true');
      this.el.nativeElement.classList.add('disabled'); // Optional: Add a CSS class for styling
    } else {
      this.el.nativeElement.removeAttribute('disabled');
      this.el.nativeElement.classList.remove('disabled');
    }
  }
}
