import { JsonPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterLinkWithHref } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SubChapter, SubChapterService } from 'src/app/subChapter.service';

@Component({
  selector: 'app-question-set-add',
  templateUrl: './sub-chapter-add.component.html',
  styleUrls: ['./sub-chapter-add.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule
],
})
export class SubChapterAddComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number },
    private dialogRef: MatDialogRef<SubChapterAddComponent>,
    private subChapterService: SubChapterService,
    private toast: ToastrService
  ) {}
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  questionSetForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    questionGroupId: new FormControl(''),
  });

  ngOnInit(): void {}
  submit() {
    const question = this.questionSetForm.value;
    const questionG: SubChapter = {
      title: question.title ?? '',
      details: question.details ?? '',
      questionGroupId: this._data.id ?? 0,
    };
    this.subChapterService.create(questionG).subscribe((res: any) => {
      this.toast.success('Sub Chapter Added successfully');
      this.dialogRef.close();
    });
  }
}
