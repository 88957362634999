import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Unit, UnitService } from '../unit.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-unit-edit',
  standalone: true,
  imports: [MatLabel, MatFormFieldModule, ReactiveFormsModule, MatInputModule],
  templateUrl: './unit-edit.component.html',
  styleUrl: './unit-edit.component.scss',
})
export class UnitEditComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: { id: number; subChapterId: number },
    private dialogRef: MatDialogRef<UnitEditComponent>,
    private unitService: UnitService,
    private toast: ToastrService
  ) {
    this.unitForm.get('subChapterId')?.setValue(Number(_data.id));
    this.id = _data.id;
  }
  id: number | undefined;
  destroy$ = new Subject<any>();
  questionGListChanges = new Subject<void>();
  unitForm = new FormGroup({
    title: new FormControl(''),
    details: new FormControl(''),
    subChapterId: new FormControl(),
  });

  ngOnInit(): void {
    this.unitService
      .getById(this._data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((u) => this.unitForm.patchValue(u));
  }
  submit() {
    if (!this.id) return;
    const unitF = this.unitForm.value;
    const unit: Unit = {
      title: unitF.title ?? '',
      details: unitF.details ?? '',
      subChapterId: unitF.subChapterId ?? 0,
    };
    this.unitService.update(this.id, unit).subscribe(() => {
      this.toast.success('Unit Updated Successfully !!');
      this.dialogRef.close();
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
