<div
  class="font-poppins flex mx-auto text-2xl text-[#C44055] text-center my-6 w-fit"
>
  Edit Question Group
</div>
@if (questionGroup$ | async; as questionGroup) {
<form
  [formGroup]="questionForm"
  class="flex flex-col m-6 p-3 sm:flex-row [&div>input]:bg-[#E8F9F0] bg-[#295E84] rounded-xl gap-5"
>
  <div
    class="flex flex-col grow flex-wrap mx-auto text-gray-200 text-center gap-3 w-full"
  >
    <div class="flex flex-wrap gap-8"></div>
    <mat-form-field>
      <mat-label class="">Title</mat-label>
      <input
        matInput
        placeholder="Enter Title"
        formControlName="title"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Faculty</mat-label>
      <mat-select formControlName="faculty">
        @for (f of getFaculties(); track f) {
        <mat-option [value]="f">
          {{ f }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="">Category</mat-label>
      <mat-select formControlName="category">
        @for (category of categories; track category.name) {
        <mat-option [value]="category.name">
          {{ category.name }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="">Details</mat-label>
      <textarea
        matInput
        placeholder="Enter Details"
        formControlName="details"
        required
      ></textarea>
    </mat-form-field>
    <div class="flex flex-wrap mx-auto gap-3">
      <button
        (click)="submit()"
        mat-raised-button
        color="primary"
        class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
      >
        Save
      </button>
      <a [routerLink]="['/home/questions-group']" href="">
        <button
          mat-raised-button
          mat-dialog-close
          color="primary"
          class="px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 focus:text-indigo-800 justify-center w-fit m-3 mx-auto"
        >
          Cancel
        </button>
      </a>
    </div>
  </div>
</form>
}
