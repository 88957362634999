import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface CustomCoinReward {
  quantity: number;
  coinFeature: string;
  transactionType: string;
  userId: string;
  message: string;
}
@Injectable({
  providedIn: 'root',
})
export class CoinTransactionService {
  constructor(private http: HttpClient) {}

  public buyCoinByAdmin(cr: any) {
    return this.http.post(`${environment.apiUrl}coin-history/create/admin`, cr);
  }
}
