import { AfterViewInit, Component, DestroyRef, computed } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { QuestionGroup } from '../../questions.model';
import { QuestionGroupService } from '../questions.service';
import { Subject, filter } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService, Branch } from 'src/app/app.service';

@Component({
  selector: 'app-question-group-add',
  templateUrl: './question-group-add.component.html',
  styleUrls: ['./question-group-add.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
  ],
})
export class QuestionGroupAddComponent implements AfterViewInit {
  constructor(
    private questionService: QuestionGroupService,
    private toastrService: ToastrService,
    private dialogRef: MatDialogRef<QuestionGroupAddComponent>,
    private destroyRef: DestroyRef,
    private route: ActivatedRoute,
    private appService: AppService
  ) {}
  questionGListChanges = new Subject<void>();
  questionForm = new FormGroup({
    title: new FormControl(''),
    faculty: new FormControl('ENGINEERING'),
    category: new FormControl('CIVIL'),
    details: new FormControl(''),
  });

  faculties = computed(() => this.appService.appConstant$()?.faculties);
  categories: Branch[] = [];

  ngAfterViewInit(): void {
    this.questionForm
      .get('faculty')
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((f) => !!f)
      )
      .subscribe((f) => {
        this.categories = this.getcategories(f!.toString());
      });
    this.questionForm
      .get('faculty')
      ?.setValue(this.route.snapshot.queryParamMap.get('faculty'));
    this.questionForm
      .get('category')
      ?.setValue(this.route.snapshot.queryParamMap.get('category'));
  }

  getFaculties(): string[] {
    const f = this.faculties();
    if (f) return Object.keys(f);
    return [];
  }
  getcategories(f: string) {
    const facultyValue = f;
    const ff = this.faculties();
    if (ff && typeof facultyValue === 'string' && ff[facultyValue]) {
      return ff[facultyValue]!;
    }
    return [];
  }
  submit() {
    const question = this.questionForm.value;
    const questionG: QuestionGroup = {
      title: question.title ?? '',
      category: question.category ?? '',
      details: question.details ?? '',
      faculty: question.faculty ?? 'ENGINEERING',
    };
    this.questionService.create(questionG).subscribe((res: any) => {
      this.toastrService.success('Added successfully', '', { timeOut: 3000 });
      this.dialogRef.close(true);
    });
  }
}
